// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

///////// <HomePromoBanner> /////////
body {
  background: #ffffff;
}

.client-container {
  width: 100%;
  min-height: 100vh;
}

.th-promo-banner-1 {
  width: 100%;
  //min-height: 572px;
  height: calc(100vh - 74px);

  //background-image: url("./../../assets/images/th-promo-banner-1.JPG");
  //background-image: image("th-promo-banner-1.JPG");
  //background-size: 100% auto;
  //background-repeat: no-repeat;

  .promo-text{
    padding: 1.5em;
    //background-color: rgba(225, 112, 85, .7);
    //background-color: rgba(214, 48, 49, .7);
    background-color: rgba(178, 190, 195, .7);
    top: 5%;
    position: absolute;
    width: 100%;

    p {
      text-transform: uppercase;
      font-weight: 500;
      text-align: center;
      font-size: 1.3em;
      color: #ffffff;
    }
  }

  .home-promo-img {
    height: 100%;
  }

  .th-promo-banner-1-btn {
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
    padding-left: 50px;
    padding-right: 50px;
    border: th-border(1.5px, white);
  }
}

////////////////////////////////////

///////// <MainSearch> /////////

.th-main-search {
  //background-color: #7fcfde;
  background-color: #38ada9;
  //min-height: 572px;
  min-height: calc(100vh - 74px);

  .th-search-input-group {
    border-radius: 0.3rem;
    margin-top: 0.25rem;
    //height: 85px;
    height: 4em;

    .destination-input {
      font-size: 1.15rem;
    }
  }

  input,
  select,
  button {
    //height: calc(2.5em + 1.5rem + 2px);
    font-family: Montserrat;
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
    line-height: 1.5;
    margin-bottom: 1em;
  }

  .th-search-button {
    text-transform: uppercase;
    //height: 85px;
    height: 4.5em;
  }

  .th-search-input-group,
  .th-search-button {
    //box-shadow: 0 0 7px 0.5px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 2px 1.5px rgba(0, 0, 0, 0.1);
  }


  .welcome-text {
    .th-main-search-title {
      font-size: 1.6em;
      margin-top: 1em;
      margin-bottom: 0.1em;
      font-weight: bold;
      color: #ffffff;
    }

    .th-main-search-subtitle {
      font-family: Montserrat;
      font-size: 1.05rem;
      margin-bottom: 1.5em;
      color: #ffffff;
    }
  }


  .input-icon {
    //border: 1px solid #ced4da;
    // border: 1px solid #95a5a6;
    background-color: #ffffff;
    border-left: none;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    box-shadow: none;
  }

  .input-group-text {
    background-color: #ffffff;
    // border: 1px solid #95a5a6;
    // border: none;
    border: 1px solid #ced4da;
    padding-right: 5px;
    border-radius: 0.3rem;
    border-right: none;

    i {
      color: #aaaaaa;
      font-size: 1.5rem;
      margin-left: 1rem;
    }
  }
}

/////////////////////////////

///////// <SectionMobileAppPromo> /////////
.th-mobile-app-promo {
  padding-bottom: 5em;
  background-color: #9fa8da;
  //background-image: image("app-download-img.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: auto 80%;
  //min-height: 80vh;

  .th-mobile-app-promo-heading {
    //padding-top: 3em;
    //margin-bottom: 4rem;
    color: white;
    font-size: 2em;
    font-weight: bold;
  }
}

/////////////////////////////

///////// <SectionBlogPostList> /////////
.th-blog-post-preview-list {
  .th-blog-post-preview-list-heading {
    font-size: 1.7em;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
}

/////////////////////////////

///////// <BlogPostPreview> /////////
.th-blog-post-preview-card {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 35px;
  border-radius: 10px;

  a {
    text-decoration: none;
    color: #5c6cc4;
  }

  .th-blog-post-preview-card-img {
    border-radius: 10px 10px 0 0;
  }

  .th-blog-post-preview-card-body {
    padding: 0.7rem;

    .th-blog-post-preview-card-title {
      font-size: 0.9rem;
      font-weight: bold;
    }

    .th-blog-post-preview-card-text {
      font-size: 0.7rem;
    }
  }

  .th-blog-post-preview-card-footer {
    background: white;
    border-radius: 0 0 10px 10px;
    padding: 0.9rem 0.7rem;

    .th-blog-post-preview-card-read-more {
      font-size: 0.7rem;
      padding-top: 0.3rem;
      margin-bottom: 0;

      .icon {
        font-size: 1rem;
        float: right;
      }
    }
  }
}

/////////////////////////////

///////// <SectionSlider> /////////
.location-section {
  margin-bottom: 4rem;

  .section-title {
    font-size: 2.2rem;
    font-weight: bold;
    color: #ff7143;
    margin-top: 2.6rem;
  }

  .section-subtitle {
    font-size: 1.2rem;
    margin-bottom: 1em;
  }

  .keyword-slider {
    margin-bottom: .5em;
    .menu-wrapper {
      min-height: 2.5em;
      padding-top: .5em;
    }

    .scroll-menu-arrow {
      position: relative;

      &:first-child {
        left: -1.9em;
      }

      &:last-child {
        right: -2em;
      }
    }

    .arrow-prev {
      padding: 0.2em 0.2em 0.2em .6em;
    }

    .arrow-next {
      padding: 0.2em .8em 0.2em 0.2em;
    }

    .arrow-prev,
    .arrow-next {
      cursor: pointer;
      font-size: 3rem;
      border: 1px solid rgba(0, 0, 0, 0.1);;
      border-radius: 50%;
      box-shadow: 0 0 2px 4px rgba(0, 0, 0, 0.1);

      &:focus {
        outline: none;
      }

      &:hover {
        box-shadow: 0 0 2px 4px #0984e3;
      }
    }

    .keyword-slider-item {
      border-radius: 12px;
      font-size: 0.8rem;
      font-weight: bold;
      border: 2px solid #aaaaaa;
      padding: 0.6rem 1.4rem;
      cursor: pointer;
      user-select: none;

      &:focus {
        outline: none;
      }

      &:not(:first-child) {
        margin-left: 10px;
      }

      &.selected {
        color: #ffffff;
        border: none;
        padding: 0;

        background: rgb(35, 150, 243); /* Old browsers */
        background: -moz-linear-gradient(
                        left,
                        rgba(35, 150, 243, 1) 0%,
                        rgba(153, 41, 179, 1) 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
                        left,
                        rgba(35, 150, 243, 1) 0%,
                        rgba(153, 41, 179, 1) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
                        to right,
                        rgba(35, 150, 243, 1) 0%,
                        rgba(153, 41, 179, 1) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2396f3', endColorstr='#9929b3', GradientType=1); /* IE6-9 */

        .keyword-slider-item-inner {
          border-radius: 12px;
          padding: calc(0.6rem + 2px) calc(1.4rem + 2px);

          /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2396f3+0,9929b3+100 */
          background: rgb(35, 150, 243); /* Old browsers */
          background: -moz-linear-gradient(
                          left,
                          rgba(35, 150, 243, 1) 0%,
                          rgba(153, 41, 179, 1) 100%
          ); /* FF3.6-15 */
          background: -webkit-linear-gradient(
                          left,
                          rgba(35, 150, 243, 1) 0%,
                          rgba(153, 41, 179, 1) 100%
          ); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(
                          to right,
                          rgba(35, 150, 243, 1) 0%,
                          rgba(153, 41, 179, 1) 100%
          ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2396f3', endColorstr='#9929b3', GradientType=1); /* IE6-9 */
        }
      }
    }
  }
}

.location-tile-grid {
  height: 550px;
  display: flex;
  flex-direction: column;
  align-content: stretch;

  .tile-item {
    position: relative;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;

    .tile-item-title {
      position: absolute;
      bottom: 0;
      width: 100%;
      color: #ffffff;
      font-size: 1.2em;
      text-transform: uppercase;
      text-align: right;
      padding: 1rem 1rem 0.5rem 0;

      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,000000+100&0+0,0.61+100 */
      background: -moz-linear-gradient(
                      top,
                      rgba(255, 255, 255, 0) 0%,
                      rgba(0, 0, 0, 0.51) 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
                      top,
                      rgba(255, 255, 255, 0) 0%,
                      rgba(0, 0, 0, 0.51) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
                      to bottom,
                      rgba(255, 255, 255, 0) 0%,
                      rgba(0, 0, 0, 0.51) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#9c000000', GradientType=0); /* IE6-9 */
    }

    &.t1 {
    }

    &.t2 {
      background-size: 100% 100%;
    }

    &.t3 {
    }

    &.t4 {
      background-size: 100% 100%;
    }

    &.t5 {
      background-size: 100% 100%;
    }

    &.t6 {
      background-size: 100% 100%;
    }

    &.t7 {
      background-size: 100% 100%;
    }

    &.t8 {
      background-size: 100% 100%;
    }

    &.t9 {
      background-size: 100% 100%;
      //background-position: 10% 45%;
    }
  }

  .box-top {
    display: flex;
    flex-direction: row;
    height: 72.5%;

    .box-t-1 {
      order: 1;
      width: 16%;
      height: 100%;
    }

    .box-t-2 {
      order: 2;
      width: 52%;
      height: 100%;
    }

    .box-t-3 {
      order: 3;
      width: 32%;
      height: 100%;

      display: flex;
      flex-direction: column;

      .box-t-3-1 {
        order: 1;
        width: 100%;
        height: 62%;
      }

      .box-t-3-2 {
        order: 2;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 38%;

        .box-t-3-2-1,
        .box-t-3-2-2 {
          width: 50%;
          height: 100%;
        }
      }
    }
  }

  .box-bottom {
    display: flex;
    flex-direction: row;
    height: 27.5%;

    .box-b-1 {
      order: 1;
      width: 32%;
      height: 100%;
    }

    .box-b-2 {
      order: 1;
      width: 16%;
      height: 100%;
    }

    .box-b-3 {
      order: 1;
      width: 16%;
      height: 100%;
    }

    .box-b-4 {
      order: 1;
      width: 36%;
      height: 100%;
    }
  }
}

/////////////////////////////

///////// <SectionFooter> /////////
.footer {
  margin-left: -15px;
  margin-right: -15px;

  .footer-section-1 {
    background-color: #f5f5f5;

    .footer-section-1-title {
      font-size: 1.2rem;
      color: #555555;
    }

    .list-title-link,
    .list-item-link {
      cursor: pointer;
      color: #888888;
      font-size: 0.65em;
      line-height: 1.5em;
      text-decoration: none;
    }

    .list-title {
      text-decoration: underline;
    }

    .item-list {
      list-style-type: none;
      padding-left: 0;

      li {
        line-height: 0.95rem;
      }
    }
  }

  .footer-section-2 {
    background-color: #424242;
    color: #eeeeee;
    font-size: 1.1rem;
    text-align: center;
    min-height: 270px;

    .footer-links {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      .footer-link {
        color: #eeeeee;
        text-decoration: none;
        font-size: 1.1rem;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
        margin-bottom: 1rem;
      }

      @media only screen and (max-width: $breakpoint-xs-max) {
        .footer-link {
          width: 100%;
          margin-bottom: 0.8rem;
        }
      }
    }
  }
}

/////////////////////////////

.search-picker-input {
  cursor: pointer;

  input {
    cursor: pointer;
  }
}


@media only screen and (max-width: $breakpoint-lg-max) {
  .th-mobile-app-promo {
    padding-top: 5rem;
    padding-bottom: 10rem;
    min-height: 80vh;
    background-size: auto 80%;
  }

  .th-main-search {

    font-size: .9em;

    .welcome-text {
      .th-main-search-subtitle {
        font-size: .8em;
      }
    }

    .th-search-button {
      height: 4.05em;
      font-weight: 500;
    }

    input {
      font-size: .9em;
    }
  }

  .th-mobile-app-promo {
    font-size: .7em;
  }
}

@media only screen and (max-width: $breakpoint-md-max) {
  .th-mobile-app-promo {
    padding-top: 3rem;
    padding-bottom: 3rem;
    min-height: 70vh;
    background-size: auto 80%;
  }

  .th-main-search {

    font-size: .8em;

    .welcome-text {
      .th-main-search-subtitle {
        font-size: .7em;
      }
    }

    .th-search-button {
      height: 3.6em;
      font-weight: 500;
    }

    input {
      font-size: .9em;
    }
  }

  .location-section {
    .section-title {
      font-size: 1.2em;
      margin-top: 2.6em;
      margin-bottom: .5em;
    }

    .section-subtitle {
      font-size: .7em;
      margin-bottom: 2em;
    }

    .keyword-slider {
      overflow-x: hidden;
      margin-bottom: 1em;

      .keyword-slider-item {
        font-size: .5em;
      }

      .arrow-prev {
        padding: 0.1em 0.1em 0.1em .7em;
      }

      .arrow-next {
        padding: 0.1em .8em 0.1em 0.1em;
      }

      .arrow-prev,
      .arrow-next {
        font-size: 2em;
        border: 1px solid rgba(0, 0, 0, 0.1);;
        border-radius: 50%;
        //box-shadow: 0px 0px 2px 4px rgba(0, 0, 0, 0.1);
        color: #0984e3;

        &:hover {
          box-shadow: 0 0 2px 4px #0984e3;
        }
      }
    }
  }

  .location-tile-grid {
    height: 40vh;

    .tile-item {
      font-size: .6em;
    }
  }

  .th-mobile-app-promo {
    font-size: .8em;
  }

  .th-blog-post-preview-list {
    .th-blog-post-preview-list-heading {
      font-size: 1.2em;
      margin-bottom: 1.5em;
    }
  }
}

@media only screen and (max-width: $breakpoint-sm-max) {
  .th-mobile-app-promo {
    background-image: none;
    padding-top: 2rem;
    padding-bottom: 2rem;
    min-height: 50vh;
  }

  .th-main-search {
    font-size: .8em;

    .welcome-text {
      .th-main-search-subtitle {
        font-size: .6em;
      }
    }
  }

  .duration-pick {
    padding: 15px;
  }

  .location-section {
    .section-title {
      font-size: 1.2em;
      margin-top: 2.6em;
      margin-bottom: .5em;
    }

    .section-subtitle {
      font-size: .6em;
      margin-bottom: 2em;
    }
  }

  .location-tile-grid {
    height: 35vh;

    .tile-item {
      font-size: .5em;

      .tile-item-title {
        text-transform: capitalize;
        padding-left: 10px;
        padding-bottom: 5px;
      }
    }
  }

  .th-mobile-app-promo {
    font-size: .5em;
  }
}
