.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #0288D1;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  margin-top: 5px;
  width: 100%;
  text-align: center;

  p {
    font-size: .9em;
    color: #757575;
    margin-top: .5em;
  }

  .uploader-icon {
    margin-top: .8em;
    width: 70px;
  }

  .strong {
    font-weight: 600;
  }
}

.upload-count {
  color: #5e5e5e;
  margin-top: 1.6em;
  margin-bottom: 0;
  margin-left: .1em;
  font-weight: 500;
}

.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px
}

.thumbs {
  //display: inline-flex;
  border-radius: 2px;
  //border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  //width: 200px;
  width: 23%;
  height: auto;
  padding: 2px;
  box-sizing: border-box;

  img {
    display: block;
    width: 100%;
    height: auto;
  }


}

.remove-passport {
  position: absolute;
  cursor: pointer;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: #2d434e;
  opacity: .6;
  margin-left: 2px;
  margin-top: 2px;

  padding: 0 5px 3px 6px;
  font-size: .7em;
  color: #ffffff;

}

.remove-passport:hover {
  opacity: 1;
  font-weight: 500;
}

.thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

@media only screen and (max-width: 576px) {
  .dropzone {
    width: 100%;
  }
  .thumbs {
    width: 45%;
  }
}

@media only screen and (max-width: 768px) {
  .dropzone {
    width: 100%;
  }
}