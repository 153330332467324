// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------





/// Regular font family
/// @type List
$text-font-stack: 'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', 'Montserrat' ,sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;


$Montserrat:  Montserrat, serif;


/// Copy text color
/// @type Color
$text-color: rgb(34, 34, 34) !default;

/// Main brand color
/// @type Color
$brand-color: rgb(229, 0, 80) !default;

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;





/// Container's maximum width
/// @type Length
$max-width: 1180px !default;





/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': 320px,
  'medium': 768px,
  'large': 1024px,
) !default;

$line-through-color: #ee5253;
$tour-deal-outline-color: #BDBDBD;
$secondary-button-hover-color: #0652DD;
$secondary-button-hover-border-color: #4a69bd;

$error-text-color: #ee5253;

$font-family-Montserrat: Montserrat, sans-serif;

$body-background-color: #ECEFF1;


/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: './../../../assets/' !default;

$breakpoint-sm-min: 576px;
$breakpoint-md-min: 768px;
$breakpoint-lg-min: 992px;
$breakpoint-xl-min: 1200px;

$breakpoint-xs-max: 575px;
$breakpoint-sm-max: 767px;
$breakpoint-md-max: 991px;
$breakpoint-lg-max: 1199px;

$focus-border-color: #0984e3;
$click-blue-color: #0D47A1;
$popup-box-shadow: 1px 2px 4px #636e72;

$inner-item-border-radius: 5px;
$passport-success-color: #00897b;
$passport-danger-color: #ff0941;

$plus-background: #C30052;
$plus-border-color: #8a3169;

$rounded-corners: true;

//min width in em
$atom-min-widths: 1, 2, 4, 8, 10, 12, 14, 16, 20, 24, 28;