.popup{
  position: absolute;
  background-color: #FAFAFA;
  z-index: 1;
  min-width: 100%;
  box-shadow: $popup-box-shadow;
}
.long-with{
  width: 350px;
  background-color: #8a3169;
}

.menu-overlay-popup{
  position: absolute;
  background-color: #ecf0f1;
  z-index: 1;
  min-width: 100%;
  min-height: 80vh;
  box-shadow: $popup-box-shadow;
}

