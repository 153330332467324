.auth-wrapper{
  .checkbox {
    label {
      font-size: .6em;
    }
  }
  .checkbox.checkbox-fill input[type="checkbox"] + .form-check-label::after,
  .checkbox.checkbox-fill input[type="checkbox"] + .cr::after {
    top: 14px;
  }

  .host-ad{
    background-color: #ff7979;
    .bg-title{
      display: inline-block;
      background-color: #fdcb6e;
      padding: .2em .8em;
    }
    .no-fees{
      font-size: 1.2em;
    }

    .steps-title{

    }
    .steps{
      font-size: .7em;
    }

    .bottom-title {
      margin-top: 3em;
      font-size: 1.2em;
      line-height: 1.3em;
      font-style: italic;
    }
  }

  .strength-bars {
    line-height: .5em;
    margin-bottom: .2em;

    .strength-bar {
      display: inline-block;
      background-color: #9E9E9E;
      border-radius: 5px;
      height: 5px;
      width: 9%;
      margin-right: .3em;
    }
  }
}

.auth-wrapper {
  .invalid-feedback{
    font-size: .6em;
  }
}

.loading-label{
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .auth-content{
    justify-content: start;
  }
}