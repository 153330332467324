.th-search-result-item-pricing {
  position: absolute;
  text-align: right;
  right: 1.3rem;
  bottom: 1.5rem;
  font-size: 1.5em;

  .line-through {
    width: 100%;
    border-bottom: 1.5px solid;
    border-color: $line-through-color;
    transform: rotate(5deg);
    position: relative;
    top: 0.7em;
  }

  .th-search-result-item-pricing-price {
    display: inline-block;

    p {
      font-size: 1.1em;
      margin-bottom: 0;
    }
  }

  .small-cents {
    font-size: .7em;
  }

  .th-search-result-item-pricing-installment-amount {
    font-size: 1.2em;
    margin-bottom: 0;
    color: #f02626;
    font-weight: bold;

    .th-search-result-item-pricing-installment-amount-currency {
      font-size: 0.8em;
    }
  }

  .th-search-result-item-pricing-installment-months {
    font-size: 0.8em;
    margin-bottom: 0;
  }
}
