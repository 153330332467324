body {
  width: 100%;
  overflow-x: hidden;
}


.tour-view {
  font-family: $Montserrat;

  min-height: 100vh;
  padding-top: 20px;

  .th-search-result-item-tag {
    padding: .2rem .8rem;
  }

  .th-search-result-item-offers {
    margin-bottom: 0;
  }

  .tour-view-card, .protection-card {
    padding: 0 2px;
    margin-bottom: 0;
  }

  .protection-card {
    background-color: transparent;
    border: 1px solid #B0BEC5;
    box-shadow: none;
  }

  .tour-view-title {
    font-size: 1.8rem;
    font-weight: 500;
  }

  .protection-img {
    max-width: 50px;
  }

  .protect-title {
    margin-bottom: 5px;
    font-size: 1.2rem;
    color: #455A64;
  }

  .protect-msg {
    font-size: .9rem;
    color: #455A64;
  }

  .tours-harbor {
    font-weight: 500;
  }

  .tour-container-title {
    font-size: 1.2rem;
    font-weight: 500;
    color: #576574;
    margin-bottom: 5px;
  }

  .package-card {
    border: 2px solid $tour-deal-outline-color;
    border-bottom: none;
    padding: 0 15px 15px;
  }
  .package-card:last-child {
    border-bottom: 2px solid $tour-deal-outline-color;
  }

  .package-name {
    font-size: 1rem;
    color: #576574;
    font-weight: 500;
    margin: 7px 0;
  }

  .flight-warning {
    font-size: .8rem;
    font-weight: 400;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .other-travelers-warning {
    font-size: .9rem;
    font-weight: 500;
    margin-top: 15px;
  }

  .price-container {
    display: inline-block;
  }

  .original-price {
    font-size: 1.5rem;
    display: block;
    margin-bottom: 0;
  }

  .small-currency {
    font-size: 1rem;
  }

  .small-cents {
    font-size: .6em;
  }

  .line-through-view {
    width: 100%;
    border-bottom: 2px solid;
    border-color: $line-through-color;
    transform: rotate(5deg);
    position: relative;
    top: 1.6em;
  }

  .discounted-price {
    font-size: 1.6rem;
    display: block;
    color: $line-through-color;
    font-weight: 600;
    margin-bottom: 0;
  }

  .months {
    font-size: .8rem;
    margin-bottom: 10px;
  }

  .book-now {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 5px 20px;
    margin-right: 0;
    background-color: #0984e3;
    border-color: #0984e3;
    //background: rgb(96,163,188);
    //background: linear-gradient(180deg, rgba(96,163,188,1) 0%, rgba(74,105,189,1) 77%, rgba(74,105,189,1) 100%);
  }

  .book-now:hover {
    background-color: #0652DD;
    border-color: #0652DD;
  }

  .risk-free {
    color: #00b894;
    font-size: .8rem;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 0;
  }

  button.view-flight{
    padding: 1px .8rem;
    font-size: 1.2em;
    vertical-align: bottom;
  }
  button.view-flight:hover{
    background-color: $secondary-button-hover-color;
    border-color: $secondary-button-hover-border-color;
  }

  .deal {
    width: calc(100% + 30px);
    position: relative;
    left: -15px;
    border: 2px solid $line-through-color;
    margin-bottom: -2px;
    z-index: 1;

    .deal-col {
      text-align: center;
      background-color: $line-through-color;
      margin-bottom: 20px;
    }

    .deal-title {
      font-size: 1.1rem;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 3px;
    }
  }

  .more-deals-trigger {
    background-color: $tour-deal-outline-color;
    cursor: pointer;

    p {
      text-align: center;
      margin: 5px 0;
      color: #000000;
      font-weight: 600;
      i {
        font-size: 1.3em;
        vertical-align: middle;
        margin-left: 5px;
        //border:
      }
    }
  }

  .react-modal{
    top: 0;
    left: 0;
    .react-modal-body-close{
      z-index: 100;
      top: 20px;
    }
    .react-modal-body-close::after, .react-modal-body-close::before {
      background: #ffffff;
      height: 2px;
      width: 20px;
    }
    .card-header:first-child{
      border-radius: 0;
    }
    .package-flight{
      max-height: 75vh;
      overflow-y: auto;
    }
    .timeline {
      margin-left: 0;
    }
    .card{
      margin-bottom: 0;
    }
    .card .card-block, .card .card-body{
      padding: 0 15px 1.25rem;
    }
  }

  .change-search-suggestion {
    font-size: 2em;
    color: #b71540;
    font-style: italic;
  }

}

.similar-tours, .home-similar {
  background-color: #E0E0E0;
  padding-bottom: 30px;
  margin-top: 5em;

  .title-similar-tours{
    font-size: 1.3rem;
    text-align: center;
    margin: .9em 0 .9em;
  }
  .background-white{
    background-color: #ffffff;
  }
  .similar-details{
    height: 6.7em;
    padding-top: 5px;
  }
  .similar-pricing{
    .th-search-result-item-pricing {
      bottom: .5em;
    }
    font-size: .8em;
  }

  .title{
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-height: 1.5em;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .rating {
    margin-bottom: 5px;
    margin-left: 10px;
    color: #1e3799;
    font-weight: 500;
    font-size: .9em;
    .digit {
      font-size: 1.2em;
      font-weight: 600;
    }
  }
  .th-search-result-item-tag{
    margin-bottom: 0;
    padding: .1rem .8rem;
  }

  //.small-currency{
  //  font-size: .7em;
  //}
  //.original-price{
  //  font-size: .8rem;
  //}
  //.discounted-price{
  //  font-size: .9rem;
  //}
  //.months{
  //  font-size: .7rem;
  //}
  //.line-through-view{
  //  top: 1em;
  //  border-bottom: 1px solid $line-through-color;
  //}
}



.height-100{
  height: 100%;
}
.similar-row{
  //height: 100%;
  height: calc(100vh - 74px);
  background-color: #dfe6e9;
  padding-top: 5px;

  .similar-tours{
    padding-bottom: 0;
  }
  .tour-view {
    min-height: 300px;
    padding: 0 1.3em;
    background-color: transparent;
  }
  .similar-tour-image-container{
    max-height: 60%;
  }
  .similar-tour-image{
    max-height: 100%;
    width: 100%;
  }
  .details-container{
    height: 40%;
  }

}

.home-similar{
  max-height: 100%;
  margin-top: 0;
  //display: flex;
  //flex-direction: column;

  .similar-item{
    height: calc(100% / 3);
    //background-color: #8a3169;
    overflow: hidden;

    .similar-tour{
      height: 100%;
    }
    .similar-tour>.row{
      height: 100%;
    }
  }


}







@media (max-width: 998px) {
  .tour-view {
    .gallery-col {
      display: none;
    }

    .tour-view-title {
      font-size: 1.2rem;
      font-weight: 500;
    }

    .protection-img {
      max-width: 40px;
    }

    .protect-title {
      margin-bottom: 5px;
      font-size: .9rem;
    }

    .protect-msg {
      font-size: .5rem;
    }

    .th-search-result-item-preffered {
      display: inline-block;
    }

    .deal {
      width: 100%;
      position: relative;
      left: 0;
      border: 2px solid $line-through-color;
    }
  }
}