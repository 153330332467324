.th-search-results {
  margin-top: 2rem;
}

.tour-link {
  color: inherit;
}

.tour-link:hover, .tour-link:active, .tour-link:focus, .tour-link:visited {
  color: inherit;
  text-decoration: none;
}

/////// <SearchResultItem> /////////
////////////////////////////////////
.th-search-result-item,
.tour-view {
  font-family: Montserrat;
  border-radius: 5px;
  padding: 0.5rem;
  font-size: 0.7rem;

  .th-search-result-item-title {
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 0.3rem;
    margin-bottom: 0.5rem;
  }

  .th-search-result-item-agent-name {
    margin-left: 0.5rem;
    margin-right: 1rem;
    //font-weight: bold;
    display: inline;
  }

  .th-search-result-item-preffered {
    display: inline;

    img {
      height: 1.3rem;
    }
  }

  .th-search-result-item-locations {
    margin-top: 0.6rem;
  }

  .th-search-result-item-facilities {
    margin-top: 0.5rem;
    //margin-bottom: 1.5rem;
  }

  .th-search-result-item-tag {
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    padding: 0.1rem 0.8rem;
    margin-right: 0.3rem;
    margin-bottom: 0.3rem;
    white-space: nowrap;
    display: inline-block;
    font-size: 1em;

    &.blue {
      //border-color: #00b4e8;
      //color: #00b4e8;
      border-color: #079992;
      color: #079992;
    }

    i {
      margin-right: 0.3rem;
    }
  }

  .th-search-result-item-stars {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;

    i {
      font-size: 0.9rem;
      color: #ffd728;
      margin-right: 0.15rem;
    }
  }

  .th-search-result-item-offers {
    margin-bottom: 1.5rem;

    .th-search-result-item-offer {
      border: 1px dashed #e7c2c2;
      padding: 2px 0.5px 2.5px 1px;
      display: inline;
      font-weight: bold;
      margin-right: 1.5rem;
      font-size: 1.2em;

      .th-search-result-item-offer-inner {
        padding: 0.1rem 0.5rem;
        background-color: #b61616;
        color: #ffffff;
        text-transform: uppercase;
      }
    }

    .th-search-result-item-offer-ends-in {
      color: #b61616;
      font-size: 0.75rem;

      i {
        font-size: 0.9rem;
        margin-right: 0.2rem;
      }
    }
  }

  .th-search-result-item-offer-mobile {
    display: none;
  }

  .th-search-result-item-rating {
    position: absolute;
    text-align: center;
    right: 0.8rem;
    top: 0;
    padding: 0.5rem 0.5rem 0.5rem 0.7rem;
    border-bottom-left-radius: 45px;
    border-bottom-right-radius: 7px;
    border-top-right-radius: 7px;
    color: #ffffff;
    font-weight: 600;

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#147fd2+0,311c91+100 */
    background: #147fd2; /* Old browsers */
    background: -moz-linear-gradient(
                    -45deg,
                    #147fd2 0%,
                    #311c91 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
                    -45deg,
                    #147fd2 0%,
                    #311c91 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
                    135deg,
                    #147fd2 0%,
                    #311c91 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#147fd2', endColorstr='#311c91', GradientType=1); /* IE6-9 fallback on horizontal gradient */

    .th-search-result-item-rating-keyword {
      font-size: 0.6rem;
      margin-bottom: 0;
    }

    .th-search-result-item-rating-number {
      font-size: 1.5rem;
      margin-bottom: 0;
    }
  }

  .th-search-result-item-gallery {
    display: flex;
    flex-flow: row wrap;

    .th-search-result-item-gallery-main-img {
      flex: 4 100%;
      padding: 0.5px;

      img {
        border-top-left-radius: 5px;
        width: 100%;
      }
    }

    .th-search-result-item-gallery-sub-img {
      flex: 1 25%;
      padding: 0.5px;

      &:nth-child(6) {
        img {
          border-bottom-left-radius: 5px;
        }
      }
    }
  }

  @media only screen and (max-width: $breakpoint-sm-max) {
    padding: 0;
    margin-bottom: 1rem;

    .th-search-result-item-details {
      padding: 0.5rem;
    }

    .th-search-result-item-gallery {
      .th-search-result-item-gallery-main-img {
        padding-top: 0;

        img {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
    }

    .th-search-result-item-rating {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 30px;
      padding: 0.3rem 0.3rem 0.5rem 0.5rem;

      right: 15px;
      margin-top: -0.5px;

      .th-search-result-item-rating-keyword {
        font-size: 0.5rem;
      }

      .th-search-result-item-rating-number {
        font-size: 1rem;
      }
    }

    .th-search-result-item-offers {
      .th-search-result-item-offer {
        display: none;
      }

      .th-search-result-item-offer-ends-in {
        color: #f02626;
        font-size: 1rem;

        i {
          font-size: 1.1rem;
          margin-right: 0.3rem;
        }
      }
    }

    .th-search-result-item-offer-mobile {
      display: block;
      padding: 0.2rem 1rem;
      position: absolute;
      top: 0;
      font-size: 0.75rem;
      color: #ffffff;
      text-transform: uppercase;
      background-color: #b61616;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  .flight-availability{
    font-family: $font-family-Montserrat;
    font-size: 1.1em;
    margin-bottom: 5px  ;
    margin-top: 0;
    padding: 5px;
    background-color: #74b9ff;

    span{
      font-weight: 600;
      font-size: .9em;
    }
  }
}

/////// <SearchResultsSidebar> /////////
////////////////////////////////////

.search-results-sidebar {
  .search-results-sidebar-filters {
    border: 0.5px solid #bbbbbb;
    border-radius: 10px;
    margin-bottom: 2rem;
  ;

    .search-results-sidebar-filters-heading {
      padding: 0.5rem 1.5rem;
      font-weight: bold;
      border-bottom: 1px solid #bbbbbb;
      font-size: 1rem;
    }

    .search-results-sidebar-filter-box {
      .search-results-sidebar-filter-item {
        font-size: 0.7rem;
        padding: 0.6rem 1.5rem;
        margin-left: 1px;
        border-bottom: 1px solid #dddddd;

        &.open {
          //background-color: #e0e0e0;
          background-color: #ffffff;
          .search-results-sidebar-filter-item-heading{
            font-weight: 600;
          }
        }

        .search-results-sidebar-filter-item-heading {
          font-size: 0.8rem;
          cursor: pointer;
          margin-bottom: .5em;

          i {
            border: 1px solid #bbbbbb;
            border-radius: 50%;
            float: right;
            padding: 2px;
          }
        }

        .form-group {
          margin-bottom: .5em;
        }

        .search-results-sidebar-filter-item-inner {
          //padding: 0.5rem 0;
          .search-results-sidebar-filter-item-price-input {
            display: inline-block;
            width: 47%;
            padding: 0.1rem 0.4rem;
            font-size: 0.7rem;
            margin-bottom: 0.5rem;
          }

          .search-results-sidebar-filter-item-price-input-separator {
            display: inline-block;
            min-width: 6%;
            text-align: center;
          }
        }
      }

      .search-results-sidebar-filter-item:last-child {
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
      }

    }

    @media only screen and (max-width: $breakpoint-sm-max) {
      margin-bottom: 1rem;
    }
  }
}

