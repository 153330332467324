.outline-label {
  @include atom-font-sizes;
  @include atom-min-width;

  display: inline-block;
  font-weight: 400;
  border: 1px solid #dddddd;
  border-radius: if($rounded-corners, 5px, null);
  cursor: pointer;

  &.clickable {
    cursor: pointer;
    label, i {
      cursor: pointer;
    }
  }

  &:hover, &.active {
    background-color: #0066d5;
    color: #f1f1f1;
  }

  &.bold {
    font-weight: 500;
  }

  &.disabled {
    cursor: not-allowed;
    label, i {
      cursor: not-allowed;
    }
  }
}