.result-list {
  list-style-type: none;

  .result-item {
    cursor: pointer;
    padding-left: 3em;
    padding-top: 0;

    .result-icon {
      color: #636e72;
      font-size: 1rem;
    }

    .result-label {
      font-family: Montserrat;
      cursor: pointer;
      font-weight: 500;
      color: #34495e;
      font-size: .8rem;
      margin-left: 15px;
      margin-bottom: 6px;
    }

    .small-label {
      font-family: Montserrat;
      cursor: pointer;
      color: #636e72;
      margin-left: 8px;
      font-size: .7rem;
      margin-bottom: 6px;
    }
  }

  .result-item:hover, .result-item.active {
    background-color: #dfe6e9;
  }
}

.search-destinations {
  padding: 5px 0;
  margin-bottom: 0;
  min-height: 100px;
}


.pick-title {
  font-family: Montserrat;
  font-size: .7em;
  font-weight: 400;
  color: #607D8B;
  margin: 10px 0 0;
  text-align: center;
}

.search-date {
  .date-pick-title {
    font-family: Montserrat;
    font-size: .7em;
    font-weight: 400;
    color: #607D8B;
    margin: 5px 0 0;
    text-align: center;
  }

  .date-pick-help {
    font-family: Montserrat;
    font-size: .6em;
    font-weight: 400;
    color: #607D8B;
    margin: 5px 15px;
    text-align: left;
  }

  .checkbox.checkbox-fill.checkbox-success input[type="checkbox"]:checked + .form-check-label::before,
  .checkbox.checkbox-fill.checkbox-success input[type="checkbox"]:checked + .cr::before {
    color: #b2bec3;
    font-size: 20px;
  }

  .checkbox.checkbox-fill input[type="checkbox"] + .form-check-label::after,
  .checkbox.checkbox-fill input[type="checkbox"] + .cr::after {
    top: -1px;
    left: 18px;
    width: 18px;
    height: 18px;
  }

  .DayPicker {
    font-size: .75em;
    font-family: Montserrat;
    max-width: 100%;
    //border: 1px solid #dddddd;
  }

  .DayPicker-wrapper {
    //padding-bottom: 0;
  }

  .DayPicker-NavButton--prev {
    left: 1.5em;
  }

  .DayPicker-Caption {
    text-align: center;
  }

  .DayPicker-Day {
    padding: .4em .45em;
    font-weight: 500;

  }

  .DayPicker-Day--selected {
    //background-color: #98c379;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #00b894;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #09c49f;
  }
}

.duration-pick, .pax-pick {
  .form-control {
    text-align: center;
    padding: 10px 5px;
    margin-bottom: 0;
    font-weight: 600;
    background-color: #ffffff;
  }

  .duration-arrow {
    font-size: 2em;
    cursor: pointer;
    //color: #ff7675;
    color: #038FCF;
    //color: #00b894;
  }

  .duration-arrow:hover {
    //color: #d63031;
    color: #0984e3;
  }
}

.pax-pick {
  .pax-type {
    font-family: Montserrat;
    font-size: 1rem;
    padding-top: 5px;
  }

  .form-control {
    height: 50px;
    padding: 10px 5px;
    text-align: center;
    margin-bottom: 0;
    font-weight: 600;
    background-color: #ffffff;
  }
}

.suggestion-loading {
  vertical-align: top;
}

.loading {
  min-height: 22px;
  min-width: 240px;
  border-radius: 2px;
  display: inline-block;
  //background-color: #dddddd;

  background: linear-gradient(120deg, #a3a3a3 33%, #e6e3e3 38%, #a3a3a3 43%, #e6e3e3 78%, #a3a3a3 83%);

  //background-size: 400% 400% !important;
  background-size: 200% 200% !important;

  animation: gradient 2s linear infinite forwards;
}

@keyframes gradient {
  0% {
    background-position: -100vw 0px;
  }
  100% {
    background-position: 100vw 0px;
  }
}

.sticky-search {
  width: 100%;
  min-height: 60px;
  //background-color: #0d47a1;
  background-color: #301c92;
  //position: fixed;
  z-index: 10;
  font-size: .7rem;
  box-shadow: 0 7px 12px 0 rgba(62, 57, 107, 0.16);

  input,
  select,
  button {
    //height: calc(2.5em + 1.5rem + 2px);
    font-family: $font-family-Montserrat;
    padding: 0.2em .5em;
    //font-size: 1.1rem;
    line-height: 1.5;
    margin-bottom: 1em;
    font-size: 1.2em;
  }

  .th-search-input-group {
    border-radius: 0.3rem;
    margin-top: 0.45rem;
    //height: 85px;
    height: 4em;

    .destination-input {
      font-size: 1rem;
    }
  }

  .th-search-button {
    text-transform: uppercase;
    //height: 85px;
    margin-top: 0.42rem;
    height: 3.4em;
    background: rgb(34, 149, 242);
    background: linear-gradient(90deg, rgba(34, 149, 242, 1) 0%, rgba(153, 42, 178, 1) 100%);
    border: none;
    font-weight: 600;
    margin-bottom: .5em;
  }

  .form-group{
    margin-bottom: 0;
  }

  .th-search-input-group,
  .th-search-button {
    //box-shadow: 0 0 7px 0.5px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 2px 1.5px rgba(0, 0, 0, 0.1);
  }

  .input-icon {
    //border: 1px solid #ced4da;
    // border: 1px solid #95a5a6;
    background-color: #ffffff;
    border-left: none;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    box-shadow: none;
  }

  .input-group-text {
    background-color: #ffffff;
    // border: 1px solid #95a5a6;
    // border: none;
    border: 1px solid #ced4da;
    padding-right: 5px;
    border-radius: 0.3rem;
    border-right: none;

    i {
      color: #aaaaaa;
      font-size: 1.5rem;
      margin-left: 0;
    }
  }

  .result-list {
    .result-item {
      padding-top: .5em;
    }

    .result-label {
      margin-bottom: .5em;
    }
  }

  .search-date{
    .pick-title{
      font-size: 1em;
    }
    .DayPicker {
      font-size: 1.1em;
    }

    .date-pick-help{
      font-size: .9em;
    }
  }

  .duration-pick{
    .pick-title{
      font-size: 1em;
    }
  }
}

.search-summary {
  display: none;
}




@media (max-width: 768px) {
  .search-results-sidebar{
    display: none;
  }

  .th-search-results {
    margin-top: 1rem !important;
  }

  .sticky-search{
    display: none;
    padding: .5em 0;

    &.fixed-top{
      margin-top: 5.6em;
    }
  }

  .mobile-show {
    display: block !important;
  }

  .search-summary {
    display: block;
    font-size: .7em;
    padding: .5em 2em;

    &.background-white {
      background-color: #ffffff;
      box-shadow: 0 3px 6px 0 #78909C;

      .summary-box{
        box-shadow: 1px 1px 2px 0 #bdc3c7;
      }
    }

    .icon-search{
      font-size: 2em;
      color: #455A64;
      cursor: pointer;
    }

    .summary-box{
      border: 1px solid #95a5a6;
      border-radius: 15px;
      background-color: #f1f1f1;
      box-shadow: 1px 1px 2px 0 #7f8c8d;
      cursor: pointer;

      label {
        cursor: pointer;
      }

      .destination{
        margin: .2em 0;
        font-weight: 500;
      }

      .summary-labels{
        font-size: .8em;
        margin-bottom: .3em;
      }
    }

  }
}













