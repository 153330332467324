.color-white{
  color: #ffffff;
}

.tours{
  background-color: transparent;
  padding: 15px;
}
.timeline{
  margin-left: 50px;

  height: 100%;
  width: 100%;
  position: relative;

  .item{
    position: relative;
    margin-bottom: 1.8em;
    //min-height: 120px;
    min-height: 144px;
  }
  .item::after{
    content: "";
    display: table;
    clear: both;
  }

  .expander{
    position: absolute;
    width: 28px;
    height: 28px;
    padding: 1px 1px;
    //margin-top: 35px;
    margin-top: 42px;
    border-radius: 20px;
    //background-color: #8a3169;
    background-color: $plus-background;
    border: 2px solid $plus-border-color;
    cursor: pointer;
    i{
      font-size: 1.4rem;
    }
  }
  .item-content{
    display: flex;
    justify-content: flex-end;
    margin-left: 40px;
    //min-height: 120px;
    min-height: 144px;
    //width:310px;
    width: 372px;
    max-width: 90%;
    background-color: #dddddd;
    border-radius: 8px;
    box-shadow: 1px 2px 2px #7A7574;
    overflow: hidden;
  }

}
.timeline::before{
  content: ' ';
  height: calc(100% - 130px);
  width: 3px;
  position: absolute;
  top: 20px;
  left: 12px;
  background-color: #dddddd;
}

.timeline::after{
  content: "";
  display: table;
  clear: both;
}
//end main Timeline



//Sub / IDay Timeline
.sub-timeline{
  position: relative;
  margin-left: 43px;

  transform-origin:top;
  transform: scaleY(1);
  transition: transform .5s ease-out;

  .main-connector{
    position: absolute;
    width: 18px;
    height: 18px;
    left: 0;
    border-radius: 10px;
    border: 3px solid #02afb6;
    top: -20px;
  }

  .sub-item{
    position: relative;
    //min-height: 112px;
    min-height: 135px;
    margin-bottom: 1em;
  }
  .air-info-item{
    //min-height: 50px;
    min-height: 60px;
  }

  .sub-item:nth-child(2){
    padding-top: 20px;
  }
  .sub-item:last-child{
    margin-bottom: 15px;
  }
  .sub-item::after{
    content: "";
    display: table;
    clear: both;
  }


  .connector{
    position: absolute;
    //width: 8px;
    //height: 8px;
    //left: 5px;
    width: 9px;
    height: 9px;
    left: 4px;
    //margin-top: 45px;
    margin-top: 54px;
    border-radius: 10px;
    border: 2px solid #02afb6;
    background-color: #ffffff;
  }
  .sub-item-content{
    display: flex;
    margin-left: 20px;
    //height: 111px;
    height: 135px;
    //width: 287px;
    width: 345px;
    max-width: 95%;
    background-color: #2d434e;
    border-radius: 8px;
    padding: 0;
    cursor: pointer;
  }
  .sub-item-content.facility-mode{
    display: block;
    //background-color: #ffffff;
    //background-color: #F5F5F5;
    background-color: #fefefe;
    border: 2px solid #3c6382;
    background-image: none !important;
    //border: 1px solid #2d434e;
    //border: 1px solid #689F38;
    //box-shadow: inset 0 0 3px #33691E;
  }


  .air-info-content{
    display: flex;
    align-items: center;
    margin-left: 20px;
    //height: auto;
    //max-height: 50px;
    max-height: 60px;
    //width: 285px;
    width: 342px;
    max-width: 95%;
    background-color: #cccccc;
    border-radius: 5px;
    cursor: auto;

    p{
      padding: 5px 15px;
      margin-bottom: 0;
      font-family: Montserrat;
      font-weight: 600;
      color: #2d434e;
    }
  }

}

.air-info-line{
  .connector{
    margin-top: 20px;
  }
}

.sub-timeline::before{
  content: ' ';
  //height: calc(100% - 75px);
  height: calc(100% - 90px);
  width: 1px;
  position: absolute;
  left: 8px;
  top: -5px;
  border-left: 1px dashed #95a5a6;
}
.air-info-line::before{
  height: calc(100% - 40px);
}

.sub-timeline::after{
  content: "";
  display: table;
  clear: both;
}

.sub-timeline.hide{
  transform-origin:top;
  transform: scaleY(0);
  transition: transform .4s ease-out;

  .sub-item{
    margin-bottom: -5px;
  }
}
.sub-timeline.hide > div{
  margin-top: -150px;
  transition: margin-top .2s .4s;
}
.air-info-line.hide > div{
  margin-top: -150px;
}

.facility-mode-close{
  //float: right;
  font-size: 1.7em;
  margin: 0 3px;
  color: #7f8fa6;
  left: 0;
  position: absolute;
  top: -5px;
  left: 330px;
}

.triangle{
  //width: 115px;
  width: 138px;
  //height: 80px;
  height: 96px;
  align-self: flex-end;
}
.day-label{
  color: #ffffff;
  //font-size: 1em;
  font-size: 1.5em;
  //margin-right: 14px;
  margin-right: 17px;
  margin-left: -15px;
  position: absolute;
  //top: 88px;
  top: 110px;
  font-family: Montserrat;
  font-weight: 600;
}

.item-content{
  background-image: url("../../images/singa/air-travel.jpg");
  background-repeat: no-repeat;
  //background-size: 310px 120px;
  background-size: 372px 144px;
}
.sub-item-content{
  background-repeat: no-repeat;
  //background-size: 287px 111px;
  background-size: 345px 135px;
}
.topic-label {
  align-self: flex-end;
  margin-bottom: 0;
  width: 100%;
  background-color: rgba(44,62,80, .6);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  //padding: 2px 10px;
  //padding: 3px 10px;
  padding: 3px 12px;
  font-family: Montserrat;
  font-weight: 500;
  //font-size: 13px;
  font-size: 1.2em;
  color: #ffffff;
  text-align: right;
}


//Facilities
.facilities-container{
  margin-top: 1em;
  //background-color: #1de9b6;
  .carousel-indicators{
    //top: 79px;
    top: 98px;
    li{
      background-color: #95afc0;
    }
    .active{
      //background-color: #FF5722;
      //background-color: #FF9800;
      background-color: #0a3d62;
    }
  }
}
.facility{
  margin-bottom: .3em;
  padding: 0 1rem;

  .facility-icon{
    //width: 100%;
    height: auto;
    //max-width: 25px;
    font-size: 2.3em;
    color: #888888;
  }
  label {
    margin-bottom: 0;
    float: left;
    margin-right: 10px;
  }
  .title{
    font-family: Montserrat;
    font-size: 1.2em;
    font-weight: 500;
    color: #607D8B;
    //margin-top: .5em;
    margin-bottom: 0;
    display: inline;
    vertical-align: top;
    //margin-left: 7px;
  }
  .remarks{
    color: #038387;

    i{
      font-size: 1.5em;
      color: #e55039;
      vertical-align: bottom;
    }

    img{
      width: 20px;
      height: auto;
      margin-top: -1px;
    }
  }
}

@media (max-width: 992px) {
  .timeline{
    margin-left: 0px;
  }
}