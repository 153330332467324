///////// <MainMenu> /////////
.th-main-menu {
  /* TODO: write media-queries */
  min-height: 74px;
  //padding-left: 7rem;
  padding-left: 2rem;
  padding-right: 3rem;
  font-size: .9em;

  .th-main-menu-brand {
    img {
      max-height: 60px;
      width: auto;
    }
  }

  .navbar-toggler{
    border: none;
  }

  @media only screen and (max-width: $breakpoint-sm-max) {
    padding-left: 4rem;
    padding-right: 3rem;
  }
  @media only screen and (max-width: $breakpoint-xs-max) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .th-main-menu-brand {
    text-align: center;
    line-height: 1.45rem;
    color: #00b4e8;
    background: transparent;
    margin-right: 3rem;

    &:active,
    &:hover,
    &:focus {
      color: #00b4e8;
    }

    .th-main-menu-brand-tours {
      font-size: 1.4rem;
      font-weight: bold;
    }
    .th-main-menu-brand-harbor {
      font-size: 1.2rem;
    }
  }

  .th-main-menu-button {
    padding: 0.6rem 1rem;
    color: #aa34d1;
    background: -webkit-linear-gradient(#00b4e8, #aa34d1, #aa34d1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;


    i {
      background: -webkit-linear-gradient(#00b4e8, #aa34d1);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &.bordered {
      border: 0.5px solid rgba(0, 0, 0, 0.1);
      border-radius: 0.3rem;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    }
  }

  .colorful-dropdown {
    background: -webkit-linear-gradient(#00b4e8, #aa34d1, #aa34d1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;


    i {
      background: -webkit-linear-gradient(#00b4e8, #aa34d1);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.3em;
    vertical-align: .05em;
    content: "";
    border-top: 0.5em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  .my-bookings-dropdown-link {
    cursor: pointer;

    i {
      font-size: 1.2em;
      background: -webkit-linear-gradient(#00b4e8, #aa34d1);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    label {
      cursor: pointer;
      margin-bottom: 0;
      margin-left: .5em;
      color: $focus-border-color;
    }



    &:after{
      display: inline-block;
      margin-left: 0.4em;
      vertical-align: .05em;
      content: "";
      border-top: 0.5em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }

    &.visible {
      &:after {
        display: inline-block;
        margin-left: 0.4em;
        vertical-align: .05em;
        content: "";
        border-bottom: .5em solid #0D47A1 !important;
        border-right: 0.3em solid transparent;
        border-top: 0;
        border-left: 0.3em solid transparent;
      }
    }
  }

  .nav-link {
    margin-left: 0.7rem;
    font-weight: 600;
    font-size: 1rem;

    @media only screen and (max-width: $breakpoint-lg-max) {
        margin-left: 0rem;
        font-size: 0.9rem;
    }
  }
}

.installment-select-container {
  min-width: 17em;
}

////////////////////////////////////
