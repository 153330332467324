.my-recent-bookings {
  .app-download {
    font-size: .8rem;

    .app-download-container {
      border-right: 1px solid #bdc3c7;
      min-height: 90%;
    }
  }

  .my-recent-bookings-label {
    font-size: .6em;
    font-weight: 500;
    color: #4B4B4B;
  }

  .recent-booking {
    background-color: #ffffff;
    font-size: 1rem;
    //border: 1px solid;
    border-radius: $inner-item-border-radius;
    box-shadow: 1px 1px 2px #888888;

    .recent-booking-img {
      img {
        width: 100%;
        height: auto;
        border-top-right-radius: $inner-item-border-radius;
        border-top-left-radius: $inner-item-border-radius
      }
    }

    .tour-summary {
      padding-bottom: .2em;
      font-size: .9em;

      .title {
        font-size: .8em;
        font-weight: 600;
        margin-bottom: 0;
      }

      .departure {
        font-size: .65em;
        display: inline-block;
        color: #057eff;
        font-weight: 600;
        margin-bottom: 0;
      }
    }

    .th-search-result-item-tag {
      border: 1px solid #dbdbdb;
      border-radius: 5px;
      padding: 0.1em 0.8em;
      margin-right: 0.3em;
      margin-bottom: 0;
      white-space: nowrap;
      display: inline-block;
      font-size: .6em;

      &.blue {
        //border-color: #00b4e8;
        //color: #00b4e8;
        border-color: #079992;
        color: #079992;
      }

      i {
        margin-right: 0.3rem;
      }
    }


    .passport-status {
      border-top: 1px solid $passport-success-color;

      &.danger {
        border-top: 2px solid $danger-color;
      }

      .upload-btn {
        padding: 2px 8px;
        font-size: .7em;
        text-transform: uppercase;

        &.uploaded {
          border-color: $passport-success-color;
          color: $passport-success-color;

          &:hover {
            background-color: #ffffff;
          }
        }
      }


      .upload-countdown-container {
        font-size: .85em;

        i {
          display: inline-block;
        }

        .end-in {
          display: inline-block;
          font-size: .8em;
          white-space: nowrap;
          min-width: 80px;
        }

        .expired-upload {
          font-size: .9em;
          //font-weight: 600;
        }
      }
    }
  }

  .view-all-my-bookings {
    a {
      text-decoration: underline;
      color: $dark-grey;
      font-size: .8em;
      font-weight: 600;
    }
  }
}

.booking-summary-pricing, .my-booking-pricing {
  //position: absolute;
  text-align: right;
  font-size: .65em;

  .line-through {
    width: 100%;
    border-bottom: 1.5px solid;
    border-color: $line-through-color;
    transform: rotate(5deg);
    position: relative;
    top: 0.7rem;
  }

  .th-search-result-item-pricing-price {
    display: inline-block;

    p {
      font-size: 1.1em;
      margin-bottom: 0;
    }
  }

  .small-cents {
    font-size: .7em;
  }

  .th-search-result-item-pricing-installment-amount {
    font-size: 1.2em;
    margin-bottom: 0;
    //color: #f02626;
    color: #686868;
    font-weight: bold;

    .th-search-result-item-pricing-installment-amount-currency {
      font-size: 0.8em;
    }
  }

  .th-search-result-item-pricing-installment-months {
    font-size: 0.8em;
    margin-bottom: 0;
  }
}


.my-bookings {
  h1 {
    font-size: 1.5em;
    color: #5f5f5f;
    font-weight: 600;
  }

  .bookings-tabs {
    font-size: 1em;

    .nav-item {
      cursor: pointer;
      width: 25%;
      border: 1px solid #929292;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      padding: 5px 10px 5px 20px;
      height: 100%;

      &:nth-child(2), &:nth-child(3) {
        width: calc(25% - 2px);
        margin: 0 1px;
      }

      .booking-tab-title {
        cursor: pointer;

        margin-bottom: 0;
        color: #4b4b4b;
        font-size: .8em;
        font-weight: 500;

        .booking-count {
          width: 30px;
          height: 30px;
          display: inline-block;
          border-radius: 50%;
          margin-left: 1.1em;
          background-color: #8c8c8c;
          font-size: .8em;
          vertical-align: middle;
          color: #ffffff;
          padding: 6px 0;
          text-align: center;

          &.show-tag {
            background-color: #1d86e3;
            color: #ffffff;
          }
        }
      }

      &.active {
        background-color: #1d86e3;
        color: #ffffff;
        border-color: #1d86e3;

        .booking-tab-title {
          color: #ffffff;

          .booking-count {
            background-color: #ffd203;
            color: #4e4e4e;
          }

        }
      }
    }

    .tab-content {
      margin-top: 1em;
      padding: 0;
      background-color: transparent;
      box-shadow: none;
    }
  }

  .bookings-app-download {
    font-size: .8em;
  }

  .my-booking-pricing {
    font-size: 1.1em;
  }

  .passport-status {
    font-size: 1.3em;

    &.danger {
    }

    .upload-btn {
      padding: 3px 20px;
      font-size: .85em;
      text-transform: uppercase;
      font-weight: 500;

      &.uploaded {
        border-color: $passport-success-color;
        color: $passport-success-color;

        &:hover {
          background-color: #ffffff;
        }
      }
    }

    .upload-countdown-container {
      font-size: .9em;
      margin-left: 1.1em;
      padding-top: 0 !important;

      i {
        display: inline-block;
      }

      .end-in {
        display: inline-block;
        font-size: .8em;
        white-space: nowrap;
        min-width: 80px;
      }

      .expired-upload {
        font-size: .9em;
        //font-weight: 600;
      }
    }
  }

  .view-tour {
    button {
      font-size: 1.4em;
      border-radius: 3px;
      padding: 4px 35px;
    }

    .booking-date {
      margin-bottom: 0;
      vertical-align: bottom;
      font-size: .8em;
      font-weight: 500;
      color: #606060;
    }
  }

  .cancelled {
    font-size: 1.4em;
    font-style: italic;
    font-weight: 400;
    text-align: center;
    color: #ee5253;

    i {
      margin-right: 5px;
    }
  }
}

.departure-date-calendar {
  font-size: .9em;

  .date-label {
    font-size: .9em;
  }

  .departure-calendar {
    label {
      margin-bottom: 0;
      vertical-align: top;
    }

    .page-bar {
      display: block;
      height: .8em;
      width: 5.6em;
    }

    .date-label {
      display: block;
      text-align: center;
      width: 100%;
      border: 1px solid #e7e7e7;
      border-top: none;
      color: #363636;
    }

    .date-of-month {
      .page-bar {
        background-color: #1d86e3;
        border-top-left-radius: 3px;
        border-top-right-radius: 2px;
      }

      .date-label {
        font-size: 2em;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 1px;
        border-right: none;
      }
    }

    .month-year {
      text-transform: uppercase;

      .page-bar {
        background-color: #4ae258;
        border-top-left-radius: 2px;
        border-top-right-radius: 3px;
      }

      .date-label {
        padding: .4em .1em;
        font-size: 1em;
        line-height: 1em;
        border-bottom-left-radius: 1px;
        border-bottom-right-radius: 3px;
      }
    }

    &.inactive {
      .date-of-month {
        .page-bar {
          background-color: #b5b5b5;
        }
      }

      .month-year {
        .page-bar {
          background-color: #cecece;
        }
      }
    }

  }
}

.booking-view {
  &.tour-view {
    background-color: #f9f9f9;

    .tour-card{
      box-shadow: 0 3px 1px 1px #dddddd;
    }
  }

  .tour-view-title {
    font-size: 1.9em;
  }

  .pax-label {
    color: #4a4a4a;

    .pax-numbers {
      color: #656565;
    }
  }

  .booking-date {
    margin-bottom: 0;
    vertical-align: bottom;
    font-size: .9em;
    font-weight: 500;
    color: #747474;

    span {
      color: #606060;
    }
  }

  .passport-status-container {
    padding: 0 27px;
  }

  .passport-status {
    padding-bottom: .5em;
    border-bottom: 1px solid $passport-success-color;

    &.danger {
      border-bottom: 2px solid $danger-color;
    }

    & div:first-child {
      text-align: right;
    }

    .upload-btn {
      &.uploaded {
        border-color: $passport-success-color;
        color: $passport-success-color;

        &:hover {
          background-color: #ffffff;
        }
      }
    }

    button {
      padding: 5px 30px;
      font-size: 1.6em;
      text-transform: uppercase;
      font-weight: 500;
    }

    .upload-countdown-container {
      font-size: 1.6em;
      padding-top: .5em !important;

      .end-in {
        font-weight: 400 !important;
      }
    }
  }
}

.invite-to-app{
  .keep-track{
    font-size: 2em;
    font-weight: 500;
    margin-bottom: .5em;
  }
  .add-label-plus{
    width: 2.8em;
    height: 2.8em;
    background-color: $plus-background;
    border-radius: 50%;
    padding: .13em .25em;
    display: inline-block;

    i{
      font-size: 2.4em;
      color: #ffffff;
    }
  }

  .add-your-group{
    font-size: 1.7em;
    font-weight: 600;
    text-transform: capitalize;
    padding-top: .1em;
    display: inline-block;
    vertical-align: top;
    color: $plus-background;
  }

  .invite-message{
    font-size: 1.1em;
    color: #000000;
  }

  .invitee{
    .add-invitee, .remove-invitee{
      width: 2.2em;
      height: 2.2em;
      border-radius: 50%;
      padding: .2em .22em;
      display: inline-block;
      cursor: pointer;
      border: 1px solid $plus-border-color;
    }

    .add-invitee {
      background-color: $plus-background;
      i{
        font-size: 1.6em;
        color: #ffffff;
      }
    }

    .remove-invitee {
      background-color: #cdcdcd;
      border: 1px solid #a9a9a9;
      i{
        font-size: 1.6em;
        color: #000000;
      }
    }

    .form-control {
      border: 1px solid $plus-background;
      background-color: #ffffff;
    }

    .form-control:focus {
      border: 2px solid $plus-background;
      box-shadow: none;
    }
  }

  .invite-group-btn{
    font-size: 1.3em;
  }

  .form-control.is-invalid, .was-validated .form-control:invalid, .was-validated .form-control:valid,
  .form-control.is-valid{
    background-position: calc(100% - 10px) calc(.4em) !important;
  }

  .store-icon{
    width: 100%;
    max-width: 9em;
  }
}


@media screen and (max-width: $breakpoint-sm-max) {
  .menu-overlay-popup {
    .my-recent-bookings {
      .app-download {
        font-size: .8rem;

        .app-download-container {
          border-right: none;
          text-align: center;

          .app-preview {
            max-width: 200px;
          }
        }

        .app-download-container > div > div {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  .my-bookings {
    h1 {
      font-size: 1em;
    }

    .bookings-tabs {
      font-size: .6em;

      .nav-item {
        padding: 5px 3px;
      }

      .nav-item {
        .booking-tab-title {
          width: 100%;
          text-align: center;

          .booking-count {
            display: none;
          }
        }
      }
    }
  }

  .booking-view {
    .passport-status-container {
      font-size: .5em;
    }
  }
}

@media screen and (min-width: $breakpoint-sm-min) and (max-width: $breakpoint-md-max) {

  .menu-overlay-popup {
    .my-recent-bookings {
      .recent-booking {
        //max-width: 400px;

      }
    }
  }
}