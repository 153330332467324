// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------

body {
    background-color: $body-background-color;
}

.checkbox.checkbox-fill input[type="checkbox"] + .form-check-label::before,
.checkbox.checkbox-fill input[type="checkbox"] + .cr::before {
    font-size: 1.3rem;
}

.checkbox input[type="checkbox"] + .form-check-label::before,
.checkbox input[type="checkbox"] + .cr::before{
    width: 1rem;
    height: 1rem;
    //border: 2px solid #607D8B;
    border-color: #607D8B;
    line-height: 10px;
    margin-right: 15px;
}

.checkbox.checkbox-fill input[type="checkbox"] + .form-check-label::after,
.checkbox.checkbox-fill input[type="checkbox"] + .cr::after {
    //border: 2px solid #607D8B;
    border-color: #607D8B;
    width: 1.2rem;
    height: 1.2rem;
    top: 7px;
    left: 3px;
}
.cr.form-label{
    font-size: .8rem;
}

.sub-title {
    font-size: 1rem;
    color: #494949;
    font-weight: 500;
}

.feature-title {
    font-size: 1rem;
    color: #494949;
    font-weight: 600;
}