.feedback-top {
  position: absolute;
  z-index: -1;
  height: 16em;
  width: 100%;
  background-image: url("../../images/feedback/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.feedback {
  margin-left: 12.5%;
  background-color: #ffffff;
  text-align: center;
  //height: 280px;
  //border-radius: 50% 15% 50% 15%;
  //border-radius: 15% 80% ;
  border-radius: 50px 300px 50px 300px;
  box-shadow: 0px 2px 5px #9E9E9E;

  p {
    text-align: center;
    margin-bottom: .6em;
  }

  .thank-you {
    color: #009688;
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: .3em;

  }

  .booking-id {
    font-size: .8em;
    color: #757575;
  }

  .email-copy {
    font-size: .8em;
    color: #201b20;
  }

  .download-voucher {
    border-radius: 8px;
    padding: 2px 12px;
    border: 1px solid #95a5a6;
    color: #95a5a6;
    font-size: .6em;
  }

  .download-voucher:hover {
    color: #ffffff;
  }
}

.feedback-loading-container{
  font-size: .9em;
}
.loading-payment {
  font-size: 2em;
  color: #757575;
}

.error-payment {
  font-size: 1em;
}

.link-no-styles{
  color: inherit;
  text-decoration: none;
  padding: 0;
  margin: 0;
}

.thumbs-up {
  width: 6em;
  margin: .5em auto auto;
}

.feedback-card {
  box-shadow: none;
  border-radius: 10px;
  margin-bottom: 4px;

  .bright {
    color: #0c75d3;
  }

  .card-header {
    border-bottom: none;
  }

  .card-title {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 1.1rem;
  }

  .card-body {
    padding: 0 20px;
  }

  .upload-status {
    font-size: .55em;
    font-weight: 500;
    margin-top: .3em;

    .status-icon {
      float: right;
      font-size: 1.2em;
      margin-top: .1em;
      font-weight: 600;
    }
  }

  .text-uploading {
    color: #0d47a1;
  }

  .go-back {
    font-weight: 500;
    font-size: .8em;
    padding: 6px 15px;
    color: #0c75d3;
    border-color: #0c75d3;

    i {
      font-size: 1.5em;
      vertical-align: inherit;
    }
  }

  .go-back:hover {
    color: #ffffff;
  }

}

.passport-form {
  border: 1px solid #bdc3c7;
  border-radius: 5px;
  padding: 10px 15px;
  max-height: 45vh;
  overflow-y: auto;

  .form-group {
    display: inline-block;
    width: 95%;
    float: right;
  }

  .child-group {
    width: 93%;
  }

  .form-control {
    background-color: #ffffff;
    padding: 4px 10px;
    border: 1px solid #95a5a6;
    font-size: .7em;
    border-radius: 3px;
    font-family: $font-family-Montserrat;
  }

  &.was-validated .form-control:valid, .form-control.is-valid,
  &.was-validated .form-control:invalid, .form-control.is-invalid{
    background-size: calc(0.85em ) calc(0.85em);
    background-position: calc(100% - 5px) calc(.775em );
  }
  &.was-validated .form-control:valid, .form-control.is-valid,
  &.was-validated .form-control:invalid, .form-control.is-invalid{
    padding-right: calc(1.5em);
  }
}

.passport-sample {
  max-width: 95%;
  height: auto;
}

.manual-upload {
  text-decoration: underline;
  font-weight: 500;
  color: #003262;
  cursor: pointer;
  font-size: .8em;
}

.upload-later {
  text-decoration: underline;
  font-weight: 500;
  color: #8e8e8e;
  cursor: pointer;
  font-size: .8em;
  margin-bottom: 1.5em;
}

.upload-later-warning {
  color: #201b20;
  font-size: 1.2em;
}

.upload-countdown {
  color: #ff1744;
  font-weight: 600;

  i {
    vertical-align: middle;
    margin-right: 5px;
    font-weight: 600;
    font-size: 1.2em;
  }

  .end-in {
    min-width: 150px;
    text-align: left;
    margin-bottom: 0;
  }
}

.mobile-app-details{
  font-size: 1rem;
}

.app-preview {
  width: 100%;
  height: auto;
}

.keep-track {
  color: #8536cf;
  font-size: 1.7em;
  font-weight: 500;
}

.download-apps {
  color: #232323;
  font-size: .9em;
  font-weight: 400;
}

.app-guide {
  font-size: .85em;
  color: #2d434e;

  .download-guide a {
    color: #1977d3;
    text-decoration: underline;
    cursor: pointer;
  }
}

.appQR {
  width: 80%;
  max-width: 6em;
  //max-width: 140px;
}

.store-icon {
  margin-top: .5em;
  width: 90%;
  max-width: 7em;
  //max-width: 170px;
}

.app-link {
  color: #757575;
  text-decoration: underline;
  font-size: .6em;
}

.tips-card {
  .tips-title {
    font-size: 1em;
    color: #34495e;
    border: 2px solid #34495e;
    padding: 8px 50px;
    border-radius: 3px;
  }

  .tips-col {
    border: 1px solid #acacac;
    padding: 8px;
    border-radius: 3px;
    display: flex;
    align-items: center;

    i {
      font-size: 3em;
      color: #ffba34;
    }

    label {
      color: #5d5d5d;
      margin-bottom: 0;
      font-size: .9em;
      margin-left: 1.5em;
      width: calc(100% - 6em);
    }
  }
}


@media (max-width: 992px) {
  .feedback-top {
    height: 12em;
    width: 100%;
    background-image: url("../../images/feedback/bg.png");
    background-size: 200% 100%;
    background-position: 50%;
    background-repeat: no-repeat;
  }

  .thumbs-up {
    width: 3em;
  }

  .feedback {
    margin-left: 8.33%;
    border-radius: 25px 100px 25px 100px;

    p {
      margin-bottom: .2em;
    }

    .thank-you {
      font-size: .9em;
      margin-bottom: .3em;
    }

    .booking-id {
      font-size: .6em;
    }

    .email-copy {
      font-size: .6em;
    }

    .download-voucher {
      border-radius: 8px;
      padding: 2px 12px;
      border: 1px solid #95a5a6;
      color: #95a5a6;
      font-size: .4em;
    }
  }

  .keep-track {
    font-size: 1em;
    font-weight: 600;
  }

  .download-apps {
    font-size: .7em;
  }
  .app-guide {
    font-size: .7em;
  }

  .tips-card {
    .tips-col {

      i {
        font-size: 3em;
        color: #ffba34;
      }

      label {
        color: #5d5d5d;
        margin-bottom: 0;
        font-size: .7em;
        margin-left: 1.5em;
        width: calc(100% - 2em);
      }
    }
  }

  .loading-payment {
    font-size: 1.5em;
  }
}
