.tour-theme-elements {

  //Radio
  .radio input[type="radio"] + .form-check-label::before, .radio input[type="radio"] + .cr::before {
    width: 18px;
    height: 18px;
    border: 2px solid #e9eaec;
  }

  .radio input[type="radio"] + .form-check-label::after, .radio input[type="radio"] + .cr::after {
    top: 15px;
  }

  .radio input[type="radio"] + .form-check-label::after, .radio input[type="radio"] + .cr::after {
    width: 10px;
    height: 10px;
    position: absolute;
    left: 4px;
  }
  .form-label{
    font-size: .9em;
  }
  //End Radio

  //checkbox
  .checkbox input[type="checkbox"] + .form-check-label::before, .checkbox input[type="checkbox"] + .cr::before{
    width: 18px;
    height: 18px;
    line-height: 14px;
    font-size: 14px;
  }
  //end checkbox

  .DayPicker{
    font-size: .8rem;
    border: 1px solid #dddddd;
  }
  .DayPickerInput{
    max-width: 100%;
    input{
      max-width: 100%;
      border-radius: 0;
      border: 1px solid #95a5a6;
      background-color: #f1f1f1;
      padding: 2px 15px;
      font-size: .9em;
      min-height: 28px;
      text-align: right;
    }
  }
  .DayPicker-Day{
    padding: .5em .6em;
  }
  .DayPicker-NavButton--prev{
    left: 1.5em;
  }
  .DayPicker-Caption{
    text-align: center;
  }

  //time picker
  .rdtPicker{
    width: 100px;
    padding: 0;
  }
  .date-pick input{
    padding: 2px 15px;
    border: 1px solid #95a5a6;
    font-size: .9em;
    min-height: 28px;
    text-align: center;
    border-radius: 0;
    letter-spacing: 1px;
    font-weight: bold;
  }
  .form-control{
    padding: 2px 15px;
    border: 1px solid #95a5a6;
    min-height: 28px;
    border-radius: 0;
  }
}
